import { Injectable } from '@angular/core';
import { Config } from '@app/config/config';
import { environment } from '../../../environments/handyhand/environment.dk';
import slugify from 'slugify';

const handyhandRoutes = {
  createTask: 'opret?createTask=Title',
  tasks: 'opgaver',
  publicProfile: 'hh/detaljer',
  accountSettings: 'indstillinger/konto/profil-indstillinger',
  signup: 'tilmeld',
  login: 'tilmeld?authDialog=true',
} as const;

const defaultPageOptions = {
  target: '_self',
} as const;

type PageOptions = {
  target?: '_self' | '_blank' | '_parent' | '_top';
};

type ProfilePageOptions = PageOptions & {
  userId: number | string;
};

type TasksPageOptions = PageOptions & {};

type TaskPageOptions = PageOptions & {
  title: string;
  city: string;
  taskId: string | number;
};

type SignupRPageOptions = PageOptions & {
  priorUrl?: string;
};

type CreateTaskPageOptions = PageOptions & {
  title?: string;
  description?: string;
};

/**
 * This service is responsible for linking to the Handyhand platform.
 *
 * All links and redirects to the handyhand.dk/platform baseOath should be handled by this service.
 */
@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  private readonly handyhandPlatformBaseHref = environment.platformUrl;
  readonly createTaskUrl = Config.Url.createTask;
  readonly tasksUrl = Config.Url.tasks;
  readonly landingUrl = this.handyhandPlatformBaseHref;

  goToCreateTask(options: CreateTaskPageOptions = defaultPageOptions) {
    const route = handyhandRoutes.createTask;
    let url = `${this.handyhandPlatformBaseHref}/${route}`;
    if (options && options.title && options.description) {
      url += `&task_title=${options.title}&task_description=${options.description}`;
    } else if (options && options.title) {
      url += `&task_title=${options.title}`;
    } else if (options && options.description) {
      url += `&task_description=${options.description}`;
    }

    window.open(url, options?.target || '_self');
    return true;
  }

  goToTasks(options: TasksPageOptions = defaultPageOptions) {
    const route = handyhandRoutes.tasks;
    const url = `${this.handyhandPlatformBaseHref}/${route}`;
    window.open(url, options?.target || '_self');
  }

  goToTask(options: TaskPageOptions) {
    const { title, city, taskId } = options;
    const slug = slugify(title + ' ' + city, {
      lower: true,
      remove: /[*+~.()'"!:@/]/g,
      locale: 'da',
    });
    const route = handyhandRoutes.tasks;
    const url = `${this.handyhandPlatformBaseHref}/${route}/${slug}/${taskId}`;
    window.open(url, options?.target || '_self');
  }

  goToProfile(options: ProfilePageOptions) {
    const route = handyhandRoutes.publicProfile;
    const url = `${this.handyhandPlatformBaseHref}/${route}/${options.userId}`;
    window.open(url, options?.target || '_self');
  }

  goToAccountSettings(options?: PageOptions) {
    const route = handyhandRoutes.accountSettings;
    const url = `${this.handyhandPlatformBaseHref}/${route}`;
    window.open(url, options?.target || '_self');
  }

  goToLogin(options?: PageOptions) {
    const route = handyhandRoutes.login;
    const url = `${this.handyhandPlatformBaseHref}/${route}`;
    window.open(url, options?.target || '_self');
  }

  goToSignup(options: SignupRPageOptions = defaultPageOptions) {
    const route = handyhandRoutes.signup;
    let url = `${this.handyhandPlatformBaseHref}/${route}`;
    if (options && options.priorUrl) {
      url += `?${options.priorUrl}`;
    }
    window.open(url, options?.target || '_self');
    return true;
  }

  goToLanding(options: PageOptions = defaultPageOptions) {
    const url = `${this.handyhandPlatformBaseHref}`;
    window.open(url, options?.target || '_self');
  }

  goToPage(page: string, options: PageOptions = defaultPageOptions) {
    const url = `${this.handyhandPlatformBaseHref}/${page}`;
    window.open(url, options?.target || '_self');
  }

  goToPrevious(delta = -1) {
    if (document.referrer?.includes(environment.platformUrl)) {
      window.history.go(delta);
    } else {
      this.goToLanding();
    }
  }
}
